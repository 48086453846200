.Container {
    background: #000;
    padding: 0 60px;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 820px;
}

.ColumnLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.ColumnLeft img {
    width: 100%;
    height: 100%;
}

.ColumnRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 50px;
}

.ColumnRight ul li {
    font-size: 20px;
    list-style-image: linear-gradient(to left bottom, rgb(23, 97, 48), rgb(13, 13, 34));
    padding-top: 40px;
}

.ColumnRight h1 {
    margin-left: -15px;
}

@media screen and (max-width: 1100px) {
    .Container {
        grid-template-columns: 1fr;
    }
    .ColumnRight {
        padding-bottom: 100px;
    }
}
